<script>
import { required, url } from "vuelidate/lib/validators";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import QlkTree from "../../../../components/widgets/qlk-tree.vue";
import Modal from "../../../../components/modals/modal-form.vue";
import api from '../../../../helpers/apirest/api';
import AddIndividualMediaButton from "../../../../components/buttons/add-individual-media-button.vue";
import ConecctionPartial from "./partials/conecction-partial.vue";
import StorePartial from "./partials/store-partial.vue";

import {
    storeComputed,
    productCategoryComputed,
} from "@/state/helpers";

const initialState = () => {
    return {
        submitted: false,
        integration: {
            name: '',
            slug: '',
            description: '',
            media_id: null,
            url: null,
            key: null,
            secret: null,
            username: null,
            password: null,

            product_categories: [],
            extra_connection_data: [],
            stores: [],
        },
        editor: ClassicEditor,
    };
}

export default {
    emits: ['onSuccess'],
    props: {
        modalId: String,
        id: Number
    },
    data() {
        return initialState();
    },
    components: {
        Modal,
        QlkTree,
        AddIndividualMediaButton,
        ckeditor: CKEditor.component,
        ConecctionPartial,
        StorePartial
    },
    computed: {
        ...productCategoryComputed,
        ...storeComputed
    },
    validations: {
        integration: {
            name: { required },
            slug: {},
            description: {},
            url: { required, url },
            key: {},
            secret: { },
            username: {},
            password: {},
            extra_connection_data: {
                $each: {
                    field: { required },
                    value: { required },
                }
            },
            stores: {
                $each: {
                    id: {required},
                }
            },
        }
    },
    methods: {
        async shown() {

            this.$store.dispatch("store/loadList");
            this.$store.dispatch("productCategory/loadList", {
                params: {
                    with_recursive_children: 1
                }
            });

            if (this.id) {
                const response = await api.get({
                    url: `integrations/${this.id}`,
                    config: {
                        withLoading: true
                    }
                });
                const integration = response?.data?.data;

                if(integration?.product_categories){
                    const product_categories = integration?.product_categories.map(item => {
                        return item?.id
                    });
                    integration.product_categories = product_categories;
                }

                if(!integration?.extra_connection_data){
                    integration.extra_connection_data = [];
                }

                this.integration = integration;
            }
        },
        updateCategories(newValues) {
            this.integration.product_categories = newValues;
        },
        async confirm() {

            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }

            const data = this.integration;
            data.media_id = this.integration?.media?.id;
            await api.save({
                url: 'integrations',
                id: this.integration?.id,
                data: data,
                config: {
                    withLoading: true
                }
            });

            this.$bvModal.hide(this.modalId);
            this.$emit("onSuccess");
        },
        cancel() {
            this.$bvModal.hide(this.modalId);
        },
        resetModal() {
            Object.assign(this.$data, initialState());
        },
        showUploadModal() {
            this.$bvModal.show(this.uploadModalId);
        },
        handleSelectedImage(image) {
             this.integration.media = image;
        }
    }
};
</script>

<template>
    <Modal :title="'Formulario de integración API'" :id="modalId" @confirm="confirm" @cancel="cancel" @shown="shown" @hidden="resetModal" :size="'extra-xl'">
        <div class="row">

            <div class="col-lg-4 col-md-12 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <h4 class="card-title">General</h4>
                        <p class="card-title-desc">
                            Ingresa los datos generales de la integración
                        </p>
                        <div class="row">
                            <div class="form-group col-12">
                                <label for="name">Nombre</label>
                                <input id="name" v-model="integration.name" type="text" class="form-control" placeholder="Ingresa un nombre"
                                    :class="{ 'is-invalid': $v.integration.name.$error && submitted }" />
                                <div v-if="!$v.integration.name.required && submitted" class="invalid-feedback">Nombre es requerido</div>
                            </div>
                            <div class="form-group col-12">
                                <label for="slug">Slug</label>
                                <p class="text-muted font-13">El «slug» es un identificador único usado internamente para reconocer el servicio. Es importante que no se modifique una vez establecido, ya que cambiarlo podría provocar errores. Normalmente, se escribe todo en minúsculas e incluye únicamente letras, números y guiones.</p>
                                <input id="slug" v-model="integration.slug" type="text" class="form-control" placeholder="Ingresa un slug"
                                :class="{ 'is-invalid': $v.integration.slug.$error && submitted }" />
                                <div v-if="$v.integration.slug.$error && submitted" class="invalid-feedback">Slug inválido</div>
                            </div>
                            <div class="col-12">
                                <label>Logo</label>
                                <AddIndividualMediaButton :onlyImages="true" v-model="integration.media"></AddIndividualMediaButton>
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>

            <div class="col-lg-8 col-md-6 col-xs-12 mb-2">
                <div class="card h-100">
                    <div class="card-body">
                        <b-tabs content-class="p-3 text-muted">
                            <b-tab active class="border-0">
                                <template v-slot:title>
                                <span class="d-inline-block d-sm-none">
                                    <i class="fas fa-home"></i>
                                </span>
                                <span class="d-none d-sm-inline-block">Descripción</span>
                                </template>
                                <div class="form-group">
                                    <p class="text-muted font-13">La descripción ofrece a los usuarios detalles esenciales sobre la integración, mejorando su entendimiento y aplicación práctica. Utilízala para explicar el proceso o incluir notas relevantes que consideres importantes.</p>
                                    <ckeditor id="description" v-model="integration.description" :editor="editor"></ckeditor>
                                </div>
                            </b-tab>
                            <b-tab>
                                <template v-slot:title>
                                <span class="d-inline-block d-sm-none">
                                    <i class="far fa-user"></i>
                                </span>
                                <span class="d-none d-sm-inline-block">Categorías</span>
                                </template>
                                <b-card-text>
                                    <p class="text-muted font-13">Selecciona las categorías que se asignarán a los productos nuevos de forma predeterminada. Los productos existenentes se mantendrán sin cambios.</p>
                                    <QlkTree
                                        :items="categoryTree"
                                        :selected="integration.product_categories"
                                        @update:selected="updateCategories"
                                    />
                                </b-card-text>
                            </b-tab>
                            <b-tab class="border-0">
                                <template v-slot:title>
                                <span class="d-inline-block d-sm-none">
                                    <i class="fas fa-home"></i>
                                </span>
                                <span class="d-none d-sm-inline-block">Tiendas</span>
                                </template>
                                <b-card-text>
                                    <StorePartial v-model="integration.stores" :stores="stores" :v="$v" :submitted="submitted"></StorePartial> 
                                </b-card-text>
                            </b-tab>                            
                            <b-tab class="border-0">
                                <template v-slot:title>
                                <span class="d-inline-block d-sm-none">
                                    <i class="fas fa-home"></i>
                                </span>
                                <span class="d-none d-sm-inline-block">Conexión</span>
                                </template>
                                <b-card-text>
                                    <ConecctionPartial v-model="integration" :v="$v" :submitted="submitted"></ConecctionPartial>
                                </b-card-text>
                            </b-tab>                            
                        </b-tabs>                      
                    </div>
                </div>
            </div>
        </div>
    </Modal>
</template>